
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const passcode = ref("");
    const router = useRouter();

    const submit = () => {
      if (passcode.value === "黑帝哥哥" || passcode.value === "hdgg") {
        router.push({ name: "Index" });
      } else {
        alert("口令错误");
      }
    };

    return { passcode, submit };
  }
});
