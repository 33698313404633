<template>
  <div class="home p-3">
    <h1>无常殿</h1>
    <h2>殿主专用放映室</h2>

    <h2 class="mt-5">请输入口令：</h2>
    <input class="form-control" v-model="passcode" type="text" />
    <button class="btn btn-primary mt-5" @click="submit">确定</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const passcode = ref("");
    const router = useRouter();

    const submit = () => {
      if (passcode.value === "黑帝哥哥" || passcode.value === "hdgg") {
        router.push({ name: "Index" });
      } else {
        alert("口令错误");
      }
    };

    return { passcode, submit };
  }
});
</script>

<style lang="stylus">
.home
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  margin-top 60px

input
  text-align center
</style>
